import * as React from 'react';
import { ButtonGroup, Button, Badge, Label } from 'reactstrap';
import '../../../styles/getty.styles.scss';
import { assetsPlaceholder } from '../../../../../../constants/assetsPlaceholder';
import { retrieveCorrectImageDisplaySize } from '../../../helpers/getty-images.helper';
import { noPreviewImage } from '../../../../../../constants/resource-constants';
import { useTranslation } from 'react-i18next';

export const GettyLazyLoadImageCell = ({
	image,
	selectedImage,
	onSelect,
	copyThumbnail,
	previewImage,
	onDownLoadSizeSelect,
	selectedItemDownloadSize,
	inBlocky,
}) => {
	const [t] = useTranslation();
	const isSelectedImage = selectedImage && selectedImage.id && selectedImage.id === image.id;
	const isSizeSelected = selectedItemDownloadSize && selectedItemDownloadSize.length > 0;
	const downLoadSizesAvailable = image.downLoadSizes && image.downLoadSizes.length > 0;
	const addItemStyle = isSelectedImage ? 'selected-border selected-download-size-background' : '';
	const addItemDownloadSizeStyle = isSizeSelected ? 'selected-size' : '';

	const preview = image.displaySizes && image.displaySizes.length > 0 ? retrieveCorrectImageDisplaySize(image.displaySizes) : noPreviewImage;
	const downLoadSizesOptions = isSelectedImage ? selectedImage.downLoadSizes : downLoadSizesAvailable && image.downLoadSizes;

	const onImageSelect = () => {
		onSelect(image);
		!inBlocky && onDownLoadSizeSelect('');
	};

	const renderDownloadOptions = (downLoadSizesOptions) => {
		if (downLoadSizesOptions && downLoadSizesOptions.length > 0) {
			return (
				<>
					<Label className='text-center mt-2 text-white'>
						<strong>{t('download_size')}</strong>
					</Label>
					<div className='flex-row'>
						{downLoadSizesOptions.map((option, index) => (
							<Badge
								onClick={() => {
									const sizeValue = document.getElementById(option.name).innerText;
									onDownLoadSizeSelect(sizeValue);
								}}
								className={`download-size-button pad2x pad2y m-2 h-75 ${selectedItemDownloadSize === option.name ? addItemDownloadSizeStyle : ''}`}
								pill
								color={isSizeSelected && selectedItemDownloadSize === option.name ? '' : 'transparent'}
								key={option.name + index}
								id={option.name}
							>
								<span className='download-size-label m-2 text-white h-50'>{option.name}</span>
							</Badge>
						))}
					</div>
				</>
			);
		} else {
			return (
				<>
					<Label className='text-center mt-2 text-white'>
						<strong>{t('no_getty_download_rights')}</strong>
					</Label>
				</>
			);
		}
	};

	return (
		<>
			{!inBlocky && (
				<div className='download-sizes-menu'>{(isSelectedImage || !downLoadSizesAvailable) && renderDownloadOptions(downLoadSizesOptions)}</div>
			)}
			<div data-qa='getty-image-lazy-load' className='image-container' title={image.id}>
				<div className={`position-relative text-center`}>
					<div
						onClick={onImageSelect}
						className={`add-image-item text-center ${addItemStyle} ${
							!downLoadSizesAvailable && !inBlocky && 'selected-download-size-background'
						}`}
					>
						<img
							className='img-fluid w-100 image rounded-top'
							onError={(i) => (i.target.src = assetsPlaceholder.blankPlaceholder)}
							alt={image.id}
							src={preview}
						/>
					</div>
				</div>
				<div className='image-footer'>
					<ButtonGroup className='actions-wrapper position-absolute display-block w-100'>
						<Button block color='secondary' className='p-0 py-1 m-0' title={t('preview_image')} onClick={() => previewImage(true, image)}>
							<i className='fa fa-image' />
						</Button>
						<Button block color='warning' className='p-0 py-1 m-0' title={t('copy_thumbnail')} onClick={(e) => copyThumbnail(e, preview)}>
							<i className='fa fa-copy' />
						</Button>
					</ButtonGroup>
				</div>
			</div>
		</>
	);
};
