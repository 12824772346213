import { featuresService } from '../../../../App';
import { FeatureTypes } from '../../../../services/feature-service/features.enum';
import 'gettyimages-api';
import axios, { AxiosInstance } from 'axios';

export default class GettyApiService {
	private instance: Record<string, any> = {};
	private httpInstance: AxiosInstance = {} as AxiosInstance;
	private gettyFeatureConfig = featuresService.getFeatureConfig(FeatureTypes.GETTY_IMAGES);

	constructor() {
		this.createGettySDKInstance();
		this.createGettyHttpInstance();
	}

	private createGettySDKInstance = () => {
		const api = require('gettyimages-api');

		if (
			this.gettyFeatureConfig &&
			this.gettyFeatureConfig.apiKey &&
			this.gettyFeatureConfig.apiSecret &&
			this.gettyFeatureConfig.username &&
			this.gettyFeatureConfig.password
		) {
			const creds = {
				apiKey: this.gettyFeatureConfig.apiKey,
				apiSecret: this.gettyFeatureConfig.apiSecret,
				username: this.gettyFeatureConfig.username,
				password: this.gettyFeatureConfig.password,
			};

			this.instance = new api(creds);
		}
	};

	private createGettyHttpInstance = () => {
		const headers = {
			'Content-Type': 'application/json',
		};
		this.httpInstance = axios.create({
			timeout: 30000,
			headers: { ...headers },
		});
	};

	public getGettyCreativeImages(phrase: string, pageOffset: number, language: string) {
		const displaySize = this.gettyFeatureConfig.displaySize;

		return this.instance
			.searchimagescreative()
			.withPage(pageOffset)
			.withPageSize(20)
			.withPhrase(phrase)
			.withResponseField(['detail_set', displaySize, 'largest_downloads', 'download_sizes'])
			.withAcceptLanguage(language)
			.execute();
	}

	public getGettyEditorialImages(phrase: string, pageOffset: number, language: string) {
		const displaySize = this.gettyFeatureConfig.displaySize;

		return this.instance
			.searchimageseditorial()
			.withPage(pageOffset)
			.withPageSize(20)
			.withPhrase(phrase)
			.withResponseField(['detail_set', displaySize, 'largest_downloads', 'download_sizes'])
			.withAcceptLanguage(language)
			.execute();
	}

	public getGettyImageDetails(pictureId: string) {
		return this.instance.images().withId(pictureId).withResponseField(['detail_set', 'largest_downloads', 'downloads']).execute();
	}

	public async downloadGettyImage(pictureId: string, downloadSize: string, productType: string) {
		let size = 0;
		return this.instance
			.images()
			.withId(pictureId)
			.withResponseField(['detail_set', 'largest_downloads', 'downloads'])
			.execute()
			.then(
				(response: any) => {
					response.images[0].download_sizes.map((current: any) => {
						if (current.name === downloadSize) {
							size = current.height;
						}
					});
					return this.instance
						.downloadsimages()
						.withId(pictureId)
						.withHeight(size)
						.withProductType(productType)
						.execute()
						.then((res: any) => {
							const downloadUri = res.uri;
							return this.httpInstance.get(downloadUri, { responseType: 'blob' });
						});
				},
				(err: Error) => {
					throw err.message;
				},
			);
	}

	public async getAccessToken() {
		return this.instance.getAccessToken().then(
			(response: any) => {
				return response && response.access_token;
			},
			(err: Error) => {
				throw err.message;
			},
		);
	}
}
